<div class="activity-control" kendoTooltip tooltipClass="tooltip" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  @if (showHeader && !saveInProgress) {
  <div cdkDragHandle class="activity-header">
  </div>
  }
  @if (!saveInProgress) {
  <div class="activity-header-info">
    @if (currentActivityId > 0) {
    <mat-form-field style="width: 5rem;">
      <mat-label>Id</mat-label>
      <input matInput (focus)="$event.target.select()" placeholder="Id" formControlName="Id" readonly="readonly" autocomplete="off" />
    </mat-form-field>
    }
    <kendo-floatinglabel text="Property" class="floatinglabel" title="{{currentProperty?.PropertyName}}   ({{currentPropertyType?.PropertyTypeName}})">
      <kendo-combobox [data]="properties"
                      [size]="'small'"
                      rounded="medium"
                      [popupSettings]="comboBoxPopupSettings"
                      formControlName="Property"
                      [fillMode]="'flat'"
                      [textField]="'IdAndPropertyName'"
                      [valueField]="'Id'"
                      [virtual]="virtual"
                      [filterable]="true"
                      (filterChange)="handlePropertiesFilter($event)"
                      (valueChange)="propertyChange($event)"
                      [valuePrimitive]="false"
                      suggest="true"
                      [kendoDropDownFilter]="filterSettings"
                      [placeholder]="'Please Select Property'"
                      class="properties-list"
                      title="{{currentProperty?.PropertyName}}   ({{currentPropertyType?.PropertyTypeName}})"
                      required>
        <ng-template kendoComboBoxItemTemplate let-dataItem>
          <div title="{{ dataItem.IdAndPropertyName }}" style="white-space: nowrap; font-size: 0.8rem; ">
            {{ dataItem.IdAndPropertyName }}
          </div>
        </ng-template>
      </kendo-combobox>
    </kendo-floatinglabel>
    <mat-form-field style="width: 8rem; margin-left: 5px">
      <mat-label>Date Created</mat-label>
      <input matInput [matDatepicker]="createddate" formControlName="CreationDate" placeholder="Date Created" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="createddate"></mat-datepicker-toggle>
      <mat-datepicker #createddate startView="month" [startAt]="form.get('CreationDate').value"></mat-datepicker>
    </mat-form-field>
    @if (isAuthorised()) {
    <div style="margin-right: 5px;">
      <mat-form-field style="width: 8rem;">
        <mat-label>Drop Dead Date</mat-label>
        <input matInput [matDatepicker]="dropdeaddate" formControlName="DropDead" placeholder="Drop Dead Date" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="dropdeaddate"></mat-datepicker-toggle>
        <mat-datepicker #dropdeaddate startView="month" [startAt]="form.get('DropDead').value"></mat-datepicker>
      </mat-form-field>
      @if (canResetDropDead) {
      <mat-icon (click)="resetDropdeadDate()" class="activity-dropdead-icon" title="Click to advance Drop Dead date by {{daysUntilDropDead}} days from today.">autorenew</mat-icon>
      }
    </div>
    }
    @if (isAuthorised()) {
    <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
    }
    @if (isAuthorised()) {
    <mat-checkbox formControlName="IsConfidential" (ngModelChange)="isConfidentialChanged($event)">Is Confidential</mat-checkbox>
    }
    @if (isAuthorised()) {
    <mat-checkbox formControlName="CanConflictProductCategory" title="Select to allow Client Category conflicts" (ngModelChange)="canClientCategoryConflictChange($event)">Allow Category Conflicts</mat-checkbox>
    }
    @if (isAuthorised() && CanAppearInWhatsOnCalendar) {
    <mat-checkbox formControlName="ShowInWhatsOnCalendar" title="Select to show activity in What's On calendar">Show In What's On</mat-checkbox>
    }
    @if (isAuthorised() && canAddActivity) {
    <fa-icon [icon]="faClone" class="fa-activity-copy-icon" (click)="raiseCopyEvent()" matTooltip="Make copy of current activity."></fa-icon>
    }
    @if (isAuthorised() && canAddActivity && canHaveFirstRightOfRefusal && isFrrEligible && form.get('Id').value > 0 && !data.IsCopy) {
    <fa-icon [icon]="faClone" class="fa-activity-create-frr-icon" (click)="raiseFrrCopyEvent()" matTooltip="Create a First Right of Refusal activity."></fa-icon>
    }
    @if (canPrintConfirmedForm) {
    <fa-icon [icon]="faPrint" title="Print Confirmed Form" (click)="print($event)"></fa-icon>
    }
  </div>
  }
  @if (!saveInProgress) {
  <div class="activity-content">
    @if (isAuthorised()) {
    <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon [ngClass]="{'valid-tab': salesTabValid,'invalid-tab': !salesTabValid}" title="Activity Id: {{currentActivityId}}">event</mat-icon>
          Booking
        </ng-template>
        <div class="tab-panel-sales">
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            <kendo-floatinglabel text="Client" style="margin-top: -11px; margin-bottom: 17px;">
              <kendo-combobox [data]="clients"
                              size="small"
                              rounded="medium"
                              formControlName="ClientId"
                              fillMode="flat"
                              textField="Name"
                              valueField="Id"
                              [virtual]="virtual"
                              [filterable]="true"
                              (filterChange)="handleClientsFilter($event)"
                              (valueChange)="clientChange($event)"
                              valuePrimitive="true"
                              required>
              </kendo-combobox>
            </kendo-floatinglabel>
            <div class="contact-selector-wrapper" [formGroup]="form">
              <fieldset>
                <legend>Client Contact Info</legend>
                <div class="contact-item contact-item-name">
                  <mat-label>
                    {{contactNameLabel}}
                    <fa-icon [icon]="faUser" class="contact-icon contact-icon-name"></fa-icon>
                  </mat-label><mat-label>{{currentClientContact}}</mat-label>
                </div>
                <div class="contact-item contact-item-phone">
                  <mat-label>
                    {{contactPhoneLabel}}
                    <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
                  </mat-label><mat-label>{{currentClientContactPhoneNo}}</mat-label>
                </div>
                <div class=" contact-item contact-item-link">
                  <mat-label (click)="clientContactEmailClick($event)">
                    {{contactEmailLabel}}
                    <fa-icon [icon]="faEnvelope" class="contact-icon"></fa-icon>
                  </mat-label><mat-label (click)="clientContactEmailClick($event)">{{currentClientContactEmail}}</mat-label>
                </div>
              </fieldset>
            </div>
            <mat-form-field style="width:unset; margin-top: 21px;">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Start Date" (dateChange)="startDateChange($event)" [min]="activityMinDate" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
              <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="width:unset">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="End Date" (dateChange)="endDateChange($event)" [min]="activityMinDate" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
              <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
            </mat-form-field>
            <button mat-raised-button color="primary" class="recurrence-button" (click)="recurrence()" title="{{recurrenceSummary}}" [disabled]="disableRecurrenceButton">
              Recurrence
              @if (currentActivity?.Recurrence?.RecurrenceType === RecurrenceTypeNone) {
              <kendo-svg-icon [icon]="icons.nonRecurrence"></kendo-svg-icon>
              }
              @if (currentActivity?.Recurrence?.RecurrenceType !== RecurrenceTypeNone) {
              <kendo-svg-icon [icon]="icons.recurrence"></kendo-svg-icon>
              }
            </button>
          </mat-card>
          <mat-card appearance="outlined" class="control-mat-card-vertical" style="margin-top: -9px;">
            <mat-form-field>
              <mat-label>Product</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Product" formControlName="Product" (change)="productChange($event)" maxlength="100" autocomplete="off" />
              <mat-error>{{getFieldErrorMessage('Product')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Booking Id</mat-label>
              <input matInput (focus)="$event.target.select()" title="{{form.get('BookingId').value}}" placeholder="Booking Id" formControlName="BookingId" maxlength="50" autocomplete="off" />
              <mat-error>{{getFieldErrorMessage('BookingId')}}</mat-error>
            </mat-form-field>
            @if ('showWebsiteUrl' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Client's Website</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Client's website url" title="{{form.get('WebsiteUrl').value}}" formControlName="WebsiteUrl" maxlength="100" autocomplete="off" />
            </mat-form-field>
            }
            <kendo-floatinglabel text="Client Categories" style="padding-bottom: 1.1rem;">
              <kendo-multiselect [autoClose]="false" [allowCustom]="false"
                                 [data]="activityService.productCategories"
                                 [textField]="'ProductCategoryName'"
                                 [valueField]="'Id'"
                                 [valuePrimitive]="false"
                                 [size]="'small'"
                                 [fillMode]="'flat'"
                                 formControlName="ProductCategories"
                                 placeholder="Client Categories"
                                 [kendoDropDownFilter]="filterSettings"
                                 (valueChange)="handleProductCategoryChange($event)">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  @if (!isRadioBroadcastPropertyType() && dataItem?.HasWarning===true) {
                  <mat-icon title="Blocked for Property" style="color: #ef5252">warning</mat-icon>
                  }
                  {{ dataItem.ProductCategoryName }}
                </ng-template>
              </kendo-multiselect>
            </kendo-floatinglabel>
            <kendo-floatinglabel text="{{platformNameLabel}}s" style="padding-bottom: 1.1rem;">
              <kendo-multiselect [autoClose]="false"
                                 [data]="propertyStations"
                                 [textField]="'StationName'"
                                 [valueField]="'Id'"
                                 [valuePrimitive]="true"
                                 [size]="'small'"
                                 [fillMode]="'flat'"
                                 formControlName="Stations"
                                 placeholder="{{platformNameLabel}}s"
                                 [kendoDropDownFilter]="filterSettings"
                                 (valueChange)="handleActivityStationsChange($event)">
              </kendo-multiselect>
            </kendo-floatinglabel>
          </mat-card>
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [required]="requireClientExec" [isDisabled]="formSalesControlsDisabled" [(value)]="currentClientExecutive" [data]="commonDataService.clientExecutives()" (valueChange)="clientExecutivesChange($event)" hasNoneSelectedOption="true">
            </contacts-selector>
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [required]="requireClientSupport" [isDisabled]="formSalesControlsDisabled" [(value)]="currentClientSupport" [data]="commonDataService.clientSupport()" (valueChange)="clientSupportChange($event)" hasNoneSelectedOption="true">
            </contacts-selector>
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [required]="requireBriefManager" [isDisabled]="formSalesControlsDisabled" [(value)]="currentBriefManager" [data]="commonDataService.briefManagers()" (valueChange)="briefManagerChange($event)" hasNoneSelectedOption="true">
            </contacts-selector>
          </mat-card>
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            <div class="activity-status-wrapper">
              <div class="status-header ">
                Activity Status
              </div>
              <kendo-dropdownlist formControlName="ActivityStatus"
                                  [ngClass]="activityStatusClass"
                                  [data]="statuses"
                                  [textField]="'Name'"
                                  [valueField]="'Value'"
                                  [valuePrimitive]="false"
                                  [size]="'small'"
                                  [fillMode]="'flat'"
                                  [rounded]="'none'"
                                  [readonly]="!canChangeStatus"
                                  (valueChange)="handleActivityStatusChange($event)"
                                  style="padding: 3px; font-size: 1rem">
              </kendo-dropdownlist>
              <div class="held-date-wrapper">
                <label class="held-date-label">{{heldUntilText}}</label>
                @if (heldUntilText) {
                <kendo-datepicker [size]="'small'" [fillMode]="'flat'" class="held-date" formControlName="OnHoldDate" placeholder="" (valueChange)="heldDateChange($event)">
                </kendo-datepicker>
                }
              </div>
            </div>
            @if ('showNoOfScripts' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div style="padding: 15px 5px;">
              <mat-card appearance="outlined" class="control-mat-card-one-line">
                <mat-form-field style="padding-right: 20px;">
                  <mat-label>Est. Weekly Impressions</mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="No. of Impressions" formControlName="Scripts" min="0" type="number" autocomplete="off" />
                </mat-form-field>
                <mat-form-field style="padding-right: 20px;">
                  <mat-label>Total Impressions</mat-label>
                  <input matInput value="{{TotalImpressions}}" min="0" type="number" autocomplete="off" readonly="readonly" />
                </mat-form-field>
              </mat-card>
            </div>
            }
            @if ('showWeeklyRevenue' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Weekly Revenue {{commonService.getSymbolForCurrency()}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Weekly Revenue {{commonService.getSymbolForCurrency()}}" formControlName="MinorPrizeValue" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showSoldCpmRate' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Sold CPM Rate {{commonService.getSymbolForCurrency()}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Sold CPM Rate {{commonService.getSymbolForCurrency()}}" formControlName="MajorPrizeValue" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if (('showCrossBookedWithActivity' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showPreRecorded' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
            <mat-card appearance="outlined" class="control-mat-card-vertical">
              @if ('showCrossBookedWithActivity' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <mat-checkbox formControlName="CrossBookedWithActivity">Notify Ad Ops</mat-checkbox>
              }
              @if ('showPreRecorded' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <mat-checkbox formControlName="PreRecorded" (ngModelChange)="hasPreRecordedChanged($event)">{{preRecordedLabelText}}</mat-checkbox>
              }
            </mat-card>
            }
          </mat-card>
          @if ('showCreditLine' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="form-group span-all-grid-columns">
            <fieldset>
              <legend>{{creditLineBoxLabelText}}</legend>
              <div class="credit-line-grid">
                @if (!isRadioBroadcastPropertyType() && (currentProperty?.Pre || currentProperty?.Mid || currentProperty?.Post)) {
                <div class="form-group">
                  <mat-card appearance="outlined" class="control-mat-card-one-line">
                    @if (currentProperty?.Pre) {
                    <mat-form-field style="width:90%">
                      <mat-label>Pre</mat-label>
                      <input matInput (focus)="$event.target.select()" placeholder="Pre" formControlName="Pre" type="number" min="0" autocomplete="off" />
                    </mat-form-field>
                    }
                    @if (currentProperty?.Mid) {
                    <mat-form-field style="width:90%">
                      <mat-label>Mid</mat-label>
                      <input matInput (focus)="$event.target.select()" placeholder="Mid" formControlName="Mid" type="number" min="0" autocomplete="off" />
                    </mat-form-field>
                    }
                    @if (currentProperty?.Post) {
                    <mat-form-field style="width:90%">
                      <mat-label>Post</mat-label>
                      <input matInput (focus)="$event.target.select()" placeholder="Post" formControlName="Post" type="number" min="0" autocomplete="off" />
                    </mat-form-field>
                    }
                  </mat-card>
                </div>
                }
                @if ('showDuration' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <mat-form-field>
                  <mat-label>Duration</mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="Duration in seconds" formControlName="FinalPCRAirVideos" min="0" type="number" autocomplete="off" />
                </mat-form-field>
                }
                @if ('showCreativeSets' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <mat-form-field>
                  <mat-label>Sets of Creative</mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="Sets of Creative" formControlName="CreativeSets" min="0" type="number" autocomplete="off" />
                </mat-form-field>
                }
                @if ('showCreditLineType' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <mat-form-field>
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="CreditLineTypeId">
                    <mat-option [value]="0" selected>None</mat-option>
                    <mat-option [value]="1">New</mat-option>
                    <mat-option [value]="2">Repeat</mat-option>
                    <mat-option [value]="3">Existing</mat-option>
                  </mat-select>
                </mat-form-field>
                }
                @if ('showCreditLineRotation' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <mat-form-field>
                  <mat-label>Rotation</mat-label>
                  <mat-select formControlName="CreditLineRotationTypeId">
                    <mat-option [value]="0" selected>None</mat-option>
                    <mat-option [value]="1">Even</mat-option>
                    <mat-option [value]="2">100%</mat-option>
                    <mat-option [value]="3">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                }
                @if ('showCreditLineDueDate' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <mat-form-field>
                  <mat-label>Credit Line Due Date</mat-label>
                  <input matInput [matDatepicker]="creditlineduedate" formControlName="CreditLineDueDate" placeholder="Credit Line Due Date" (dateChange)="creditLineDueDateChange($event)" autocomplete="off">
                  <mat-datepicker-toggle matSuffix [for]="creditlineduedate"></mat-datepicker-toggle>
                  <mat-datepicker #creditlineduedate startView="month" [startAt]="form.get('CreditLineDueDate').value"></mat-datepicker>
                </mat-form-field>
                }
                @if ('showCreativeBriefUrl' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <document-uploader formControlName="CreativeBriefUrl"
                                   emptyMsg="No Attachments"
                                   subFolder="CreativeBriefs"
                                   [uploadsFolder]="configurationService?.cbSettings?.uploadsDirectory"
                                   [recordId]="form.get('Id')?.value"
                                   [sizeLimit]="configurationService.cbSettings().creativeBriefSizeLimit"
                                   showLabel="true"
                                   labelText="Creative Brief"
                                   style="margin-left:20px">
                </document-uploader>
                }
                <kendo-formfield class="span-all-grid-columns">
                  <kendo-floatinglabel text="{{creditLineLabelText}}">
                    <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="5" selectOnFocus="true" title="{{form.get('CreditLine')?.value}}" formControlName="CreditLine" maxlength="2000"></kendo-textarea>
                  </kendo-floatinglabel>
                  <kendo-formerror>{{creditLineLabelText}} is required</kendo-formerror>
                </kendo-formfield>
                @if ('showApprovedVerticals' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
                <div class="span-all-grid-columns">
                  <mat-checkbox formControlName="PreRecorded" (change)="inAndOutChange($event)">Ins & Outs</mat-checkbox>
                  <kendo-floatinglabel text="Approved Verticals">
                    <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{form.get('MechanicDescription').value}}" formControlName="MechanicDescription" maxlength="500" autocomplete="off"></kendo-textbox>
                  </kendo-floatinglabel>
                </div>
                }
                <div class="span-all-grid-columns">
                  <kendo-floatinglabel text="{{creditLineNotesLabelText}}">
                    <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="4" selectOnFocus="true" title="{{form.get('CreditLineNotes')?.value}}" formControlName="CreditLineNotes" maxlength="2000"></kendo-textarea>
                  </kendo-floatinglabel>
                </div>
              </div>
            </fieldset>
          </div>
          }
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon [ngClass]="{'valid-tab': implementationTabValid,'invalid-tab': !implementationTabValid}">local_shipping</mat-icon>
          Delivery
        </ng-template>
        <div class="tab-panel-implementation">
          @if(('showNewsletter' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showDailyUpdate' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showPhotoBrief' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showVideoBrief' | activityDisplayField: currentPropertyType?.PropertyTypeName))
          {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showNewsletter' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Newsletter</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Newsletter" formControlName="Newsletter" min="0" type="number" />
            </mat-form-field>
            }
            @if ('showDailyUpdate' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Daily Update</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Daily Update" title="{{form.get('DailyUpdate').value}}" formControlName="DailyUpdate" maxlength="100" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showPhotoBrief' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Photo Brief</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Photo Brief" title="{{form.get('PhotoBrief').value}}" formControlName="PhotoBrief" maxlength="200" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showVideoBrief' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Video Brief</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Video Brief" title="{{form.get('VideoBrief').value}}" formControlName="VideoBrief" maxlength="200" autocomplete="off" />
            </mat-form-field>
            }
          </mat-card>
          }
          @if(!isRadioBroadcastPropertyType())
          {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showHashtags' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Hashtags</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Hashtags" title="{{form.get('PhotoBrief').value}}" formControlName="PhotoBrief" maxlength="200" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showDateDueToAdOps' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field style="width:12rem">
              <mat-label>Audio Due To Ad Ops</mat-label>
              <input matInput [matDatepicker]="dateDueToAdOps" formControlName="DateDueToAdOps" placeholder="Audio Due To Ad Ops" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="dateDueToAdOps"></mat-datepicker-toggle>
              <mat-datepicker #dateDueToAdOps startView="month" [startAt]="form.get('DateDueToAdOps').value"></mat-datepicker>
            </mat-form-field>
            }
          </mat-card>
          }
          @if (isRadioBroadcastPropertyType() && ('showExclusive' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showDigital' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showHasEntryForm' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital : currentActivity?.IsSold) || (('showHasImage' | activityDisplayField: currentPropertyType?.PropertyTypeName) && !isNew() && formSSI.get('Id').value > 0)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showExclusive' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="Exclusive">{{exclusiveLabelText}}</mat-checkbox>
            }
            @if ('showDigital' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="Digital">Digital</mat-checkbox>
            }
            @if ('showHasEntryForm' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital : currentActivity?.IsSold) {
            <mat-checkbox formControlName="HasEntryForm">{{hasEntryFormLabelText}}</mat-checkbox>
            }
            @if (('showHasImage' | activityDisplayField: currentPropertyType?.PropertyTypeName) && !isNew() && formSSI.get('Id').value > 0) {
            <document-uploader formControlName="ImageUrl"
                               emptyMsg="No Attachments"
                               subFolder="Images"
                               [uploadsFolder]="configurationService?.cbSettings?.uploadsDirectory"
                               [recordId]="form.get('Id')?.value"
                               [sizeLimit]="configurationService.cbSettings().imageSizeLimit"
                               showLabel="true"
                               labelText="Attachment"
                               style="margin-left:20px">
            </document-uploader>
            }
          </mat-card>
          }
          @if (('showHasAudio' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showHasVideo' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital) || ('showHasPhotos' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital) || (('showHasLogo' | activityDisplayField: currentPropertyType?.PropertyTypeName)  && !isNew() && formSSI.get('Id').value > 0)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showHasAudio' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="HasAudio">{{hasAudioLabelText}}</mat-checkbox>
            }
            @if ('showHasVideo' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital) {
            <mat-checkbox formControlName="HasVideo">{{hasVideoLabelText}}</mat-checkbox>
            }
            @if ('showHasPhotos' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital) {
            <mat-checkbox formControlName="HasPhotos">{{hasPhotosLabelText}}</mat-checkbox>
            }
            @if (('showHasLogo' | activityDisplayField: currentPropertyType?.PropertyTypeName) && !isNew() && formSSI.get('Id').value > 0) {
            <document-uploader formControlName="LogoUrl"
                               emptyMsg="No Attachments"
                               subFolder="Logos"
                               [uploadsFolder]="configurationService?.cbSettings?.uploadsDirectory"
                               [recordId]="form.get('Id')?.value"
                               [sizeLimit]="configurationService.cbSettings().logoSizeLimit"
                               showLabel="true"
                               labelText="Logo"
                               style="margin-left:20px">
            </document-uploader>
            }
          </mat-card>
          }
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showProgrammer' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [isDisabled]="formImplemenationControlsDisabled" [required]="requireProgrammer" [(value)]="currentProgrammer" [data]="commonDataService.programmers()" (valueChange)="programmerChange($event)" hasNoneSelectedOption="true">
            </contacts-selector>
            }
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [isDisabled]="formImplemenationControlsDisabled" [(value)]="currentNationalProjectManager" [data]="commonDataService.nationalProjectManagers()" (valueChange)="nationalProjectManagerChange($event)" hasNoneSelectedOption="true">
            </contacts-selector>
          </mat-card>
          @if (('showMinorPrize' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showMinorPrizeValue' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showMinorPrizeQuantity' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showMinorPrizeHowFulFilled' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showMinorPrize' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div style="width:100%; margin-bottom: 7px">
              <kendo-floatinglabel text="{{minorPrizeLabelText}}">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{form.get('MinorPrize').value}}" formControlName="MinorPrize" maxlength="500" autocomplete="off" (ngModelChange)="minorPrizeChanged($event)"></kendo-textbox>
              </kendo-floatinglabel>
            </div>
            }
            @if ('showMinorPrizeValue' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{minorPrizeValueLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{minorPrizeValueLabelText}}" formControlName="MinorPrizeValue" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showMinorPrizeQuantity' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{minorPrizeQtyLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{minorPrizeQtyLabelText}}" formControlName="MinorPrizeQuantity" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showMinorPrizeHowFulFilled' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{minorPrizeFulfillmentLabelText}}</mat-label>
              <textarea matInput matAutosizeMaxRows="4" rows="4" (focus)="$event.target.select()" placeholder="{{minorPrizeFulfillmentLabelText}}" title="{{form.get('MinorPrizeHowFulFilled').value}}" formControlName="MinorPrizeHowFulFilled" maxlength="500" autocomplete="off"></textarea>
            </mat-form-field>
            }
          </mat-card>
          }
          @if (('showMajorPrize' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showMajorPrizeValue' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showMajorPrizeQuantity' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showMajorPrizeHowFulFilled' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showMajorPrize' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div style="width: 100%; margin-bottom: 7px">
              <kendo-floatinglabel text="{{majorPrizeLabelText}}">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{form.get('MajorPrize').value}}" formControlName="MajorPrize" maxlength="500" autocomplete="off" (ngModelChange)="majorPrizeChanged($event)"></kendo-textbox>
              </kendo-floatinglabel>
            </div>
            }
            @if ('showMajorPrizeValue' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{majorPrizeValueLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{majorPrizeValueLabelText}}" formControlName="MajorPrizeValue" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showMajorPrizeQuantity' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{majorPrizeQtyLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{majorPrizeQtyLabelText}}" formControlName="MajorPrizeQuantity" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showMajorPrizeHowFulFilled' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{majorPrizeFulfillmentLabelText}}</mat-label>
              <textarea matInput matAutosizeMaxRows="4" rows="4" (focus)="$event.target.select()" title="{{form.get('MajorPrizeHowFulFilled').value}}" placeholder="{{majorPrizeFulfillmentLabelText}}" formControlName="MajorPrizeHowFulFilled" maxlength="500" autocomplete="off"></textarea>
            </mat-form-field>
            }
          </mat-card>
          }
          @if (('showFirstDayAirCheck' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showScreenShots' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showFinalPCR' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showFirstDayAirCheck' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div class="form-group-first-day-air-check">
              <mat-card appearance="outlined" class="control-mat-card-one-line">
                <mat-form-field class="pcr-date-field">
                  <mat-label>{{firstDayAirCheckBoxLabelText}}</mat-label>
                  <input matInput [matDatepicker]="FirstDayAirCheckDate" formControlName="FirstDayAirCheckDate" placeholder="{{firstDayAirCheckBoxLabelText}}" autocomplete="off">
                  <mat-datepicker-toggle matSuffix [for]="FirstDayAirCheckDate"></mat-datepicker-toggle>
                  <mat-datepicker #FirstDayAirCheckDate startView="month"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="FirstDayAirCheckNo" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </mat-card>
            </div>
            }
            @if ('showScreenShots' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div class="form-group-final-pcr">
              <mat-card appearance="outlined" class="control-mat-card-one-line">
                <mat-form-field class="pcr-date-field">
                  <mat-label>Screen Shot</mat-label>
                  <input matInput [matDatepicker]="ScreenShotsDate" formControlName="ScreenShotsDate" placeholder="Screen Shot" autocomplete="off">
                  <mat-datepicker-toggle matSuffix [for]="ScreenShotsDate"></mat-datepicker-toggle>
                  <mat-datepicker #ScreenShotsDate startView="month"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="ScreenShotsNo" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </mat-card>
              @if ('showScreenshotPhotosAndVideos' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <div class="final-pcr-number-field">
                <label>Photos</label>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="FinalPCRAirPhotos" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </div>
              }
              @if ('showScreenshotPhotosAndVideos' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <div class="final-pcr-number-field" style=" margin-top: -30px;">
                <label>Video</label>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="FinalPCRAirVideos" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </div>
              }
            </div>
            }
            @if ('showFinalPCR' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div class="form-group-final-pcr">
              <mat-card appearance="outlined" class="control-mat-card-one-line">
                <mat-form-field class="final-pcr-date-field">
                  <mat-label>Final PCR</mat-label>
                  <input matInput [matDatepicker]="FinalPCRDate" formControlName="FinalPCRDate" placeholder="Final PCR" autocomplete="off">
                  <mat-datepicker-toggle matSuffix [for]="FinalPCRDate"></mat-datepicker-toggle>
                  <mat-datepicker #FinalPCRDate startView="month"></mat-datepicker>
                </mat-form-field>
              </mat-card>
              @if ('showFinalPcrAirChecks' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <div class="final-pcr-number-field">
                <label>{{finalPcrAirCheckLabelText}}</label>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="FinalPCRAirCheck" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </div>
              }
              @if ('showFinalPcrPhotos' | activityDisplayField: currentPropertyType?.PropertyTypeName : false : false : currentActivity?.HasPhotos) {
              <div class="final-pcr-number-field" style="margin-top: -30px;">
                <label>Photos</label>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="FinalPCRAirPhotos" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </div>
              }
              @if ('showFinalPcrVideos' | activityDisplayField: currentPropertyType?.PropertyTypeName : false : false : currentActivity?.HasVideo) {
              <div class="final-pcr-number-field" style="margin-top: -30px;">
                <label>Video</label>
                <mat-form-field class="pcr-form-field">
                  <mat-label></mat-label>
                  <input matInput (focus)="$event.target.select()" placeholder="" formControlName="FinalPCRAirVideos" min="0" type="number" autocomplete="off" />
                </mat-form-field>
              </div>
              }
            </div>
            }
          </mat-card>
          }
          @if ('showMechanicType' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="span-all-grid-columns">
            <fieldset>
              <legend>{{mechanicTypeLabelText}}</legend>
              <div style="display:block;width:100%;flex-wrap:nowrap">
                <mat-form-field style="width:100%;margin:0">
                  <mat-select formControlName="MechanicType" placeholder="{{mechanicTypeLabelText}}" [compareWith]="equalsMT">
                    @for (mechanicType of calendarsService.mechanicTypes() | filter:[{Disabled: false}]; track mechanicType.Id)
                    {
                    <mat-option [value]="mechanicType">{{mechanicType.MechanicLabelText}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="display:block;width:100%;flex-wrap:nowrap">
                <div style="width:100%">
                  <mat-form-field style="width:100%;margin:0">
                    <mat-label>{{form.get('MechanicType')===null || form.get('MechanicType')===undefined || form.get('MechanicType').value===null || form.get('MechanicType').value===undefined || form.get('MechanicType').value==='' ? 'Mechanic' : form.get('MechanicType').value.MechanicLabelText}}</mat-label>
                    <textarea matInput matAutosizeMaxRows="4" rows="4" (focus)="$event.target.select()" title="{{form.get('MechanicDescription').value}}" placeholder="{{form.get('MechanicType')===null || form.get('MechanicType')===undefined || form.get('MechanicType').value===null || form.get('MechanicType').value===undefined || form.get('MechanicType').value==='' ? 'Mechanic' : form.get('MechanicType').value.MechanicLabelText}}" formControlName="MechanicDescription" maxlength="500" autocomplete="off"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </fieldset>
          </div>
          }
          @if ('showPCRSpecialRequest' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <mat-form-field class="span-all-grid-columns">
            <mat-label>PCR Special Request</mat-label>
            <textarea matInput matAutosizeMaxRows="4" rows="4" (focus)="$event.target.select()" title="{{form.get('PCRSpecialRequest').value}}" placeholder="PCR Special Request" formControlName="PCRSpecialRequest" maxlength="500" autocomplete="off"></textarea>
          </mat-form-field>
          }
          @if ('showMechanicDescription' | activityDisplayField: currentPropertyType?.PropertyTypeName : currentActivity?.Digital) {
          <mat-form-field class="span-all-grid-columns">
            <mat-label>{{mechanicTypeDescLabelText}}</mat-label>
            <textarea matInput matAutosizeMaxRows="4" rows="4" (focus)="$event.target.select()" title="{{form.get('MechanicDescription').value}}" placeholder="{{mechanicTypeDescLabelText}}" formControlName="MechanicDescription" maxlength="500" autocomplete="off"></textarea>
          </mat-form-field>
          }
          @if (!isRadioBroadcastPropertyType()) {
          <div class="form-group span-all-grid-columns">
            <kendo-label text="Extensions">
              @if (!isRadioBroadcastPropertyType()) {
              <activity-extensions formControlName="ActivityExtensions" (valueChange)="activityExtensionChange($event)"></activity-extensions>
              }
            </kendo-label>
          </div>
          }
        </div>
      </mat-tab>
      @if ('showSsiTab' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
      <mat-tab>
        <ng-template mat-tab-label>
          <div [ngClass]="{'valid-tab': stationSpecificInfoTabValid && (!activityService.stationIdsWithInvalidValues || activityService.stationIdsWithInvalidValues.length === 0),'invalid-tab': !stationSpecificInfoTabValid || (activityService.stationIdsWithInvalidValues && activityService.stationIdsWithInvalidValues.length > 0)}">
            @if (isRadioBroadcastPropertyType()) {
            <fa-icon [icon]="faBroadcastTower" style="padding-right:0.35rem"></fa-icon>
            }
            {{tabLabel}}
          </div>
        </ng-template>
        <div class="tab-panel-ssi" [formGroup]="formSSI">
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            <div>
              <kendo-dropdownlist #stations [(value)]="currentStationId"
                                  [data]="activityStations"
                                  [textField]="'StationName'"
                                  [valueField]="'Id'"
                                  [valuePrimitive]="true"
                                  [size]="'small'"
                                  [fillMode]="'flat'"
                                  [rounded]="'none'"
                                  (valueChange)="stationChange($event)"
                                  style="width: 85%">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  @if (stations?.isOpen && stationControlsAllValid(dataItem.Id)) {
                  <mat-icon style="color:green; padding-right:5px">done</mat-icon>
                  }
                  @if (stations?.isOpen && !stationControlsAllValid(dataItem.Id)) {
                  <mat-icon style="color: red; padding-right: 5px">clear</mat-icon>
                  }
                  {{dataItem.StationName}}
                </ng-template>
              </kendo-dropdownlist>
              @if (currentActivity?.ActivityStationInfos?.length > 1) {
              <fa-icon [icon]="faClone" style="cursor: pointer; margin-left: 5px;" (click)="duplicateCurrentStationInfo()" matTooltip="Duplicate current {{this.platformNameLabel}} specific information across all {{this.platformNameLabel}}s."></fa-icon>
              }
              @if (!stationSpecificInfoTabValid || (activityService.stationIdsWithInvalidValues && activityService.stationIdsWithInvalidValues.length > 0)) {
              <div class="invalid-control-indicator" title="There are {{this.platformNameLabel}} with invalid or missing data.">*</div>
              }
            </div>
            @if (!isRadioBroadcastPropertyType()) {
            <div class="contact-selector-wrapper">
              <fieldset>
                <legend>Vendor Contact Info</legend>
                <div class="contact-item contact-item-name">
                  <mat-label>
                    {{contactNameLabel}}
                    <fa-icon [icon]="faUser" class="contact-icon contact-icon-name"></fa-icon>
                  </mat-label><mat-label>{{currentStationContact}}</mat-label>
                </div>
                <div class="contact-item contact-item-phone">
                  <mat-label>
                    {{contactPhoneLabel}}
                    <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
                  </mat-label><mat-label>{{currentStationContactPhoneNo}}</mat-label>
                </div>
                <div class=" contact-item contact-item-link">
                  <mat-label (click)="stationContactEmailClick($event)">
                    {{contactEmailLabel}}
                    <fa-icon [icon]="faEnvelope" class="contact-icon"></fa-icon>
                  </mat-label><mat-label (click)="stationContactEmailClick($event)">{{currentStationContactEmail}}</mat-label>
                </div>
              </fieldset>
            </div>
            }
            @if (isRadioBroadcastPropertyType()) {
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [isDisabled]="formSSIControlsDisabled" [(value)]="currentProjectManager" [data]="projectManagersForStation" (valueChange)="projectManagerChange($event)" hasNoneSelectedOption="true"></contacts-selector>
            }
            @if (!isRadioBroadcastPropertyType()) {
            <contacts-selector contactPopUpClass="popup-class-activity-contact" [isDisabled]="formSSIControlsDisabled" [(value)]="currentAdOps" [data]="adOpsForStation" (valueChange)="adOpsChange($event)" hasNoneSelectedOption="true"></contacts-selector>
            }
          </mat-card>
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showActivityName' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div style="width: 100%; margin-bottom: 7px; margin-top: -5px;">
              <kendo-floatinglabel text="{{activityNameLabelText}}">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{formSSI.get('ActivityName').value}}" formControlName="ActivityName" maxlength="100" autocomplete="off"></kendo-textbox>
              </kendo-floatinglabel>
            </div>
            }
            @if (false) {
            <mat-form-field>
              <mat-label>Credit Line Key No.</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Credit Line Key Number" formControlName="CreditLineKeyNo" maxlength="100" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showIsFreeOfCharge' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="IsFreeOfCharge" title="Select if activity is free of charge for this station" style="padding: 0.5rem 0" (ngModelChange)="isFreeOfChargeChanged($event)">FOC</mat-checkbox>
            }
            @if (('showApprovedScriptAttachment' | activityDisplayField: currentPropertyType?.PropertyTypeName) && !isNew() && formSSI.get('ActivityId').value > 0 && formSSI.get('StationId').value > 0) {
            <document-uploader formControlName="ApprovedScriptUrl"
                               emptyMsg="No Attachment"
                               subFolder="ApprovedScripts"
                               [uploadsFolder]="configurationService?.cbSettings?.uploadsDirectory"
                               [recordId]="formSSI.get('ActivityId')?.value"
                               [recordSubId]="formSSI.get('StationId')?.value"
                               [sizeLimit]="configurationService.cbSettings().approvedScriptSizeLimit"
                               showLabel="true"
                               labelText="Approved Scripts"
                               style="margin-left:20px">
            </document-uploader>
            }
          </mat-card>
          @if (('showMultimediaRecovery' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showRevenue' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showProduction' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showRate' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showRate' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-card appearance="outlined" class="control-mat-card-one-line">
              <mat-form-field>
                <mat-label>{{rateLabelText}}</mat-label>
                <input matInput (focus)="$event.target.select()" placeholder="{{rateLabelText}}" formControlName="Rate" type="number" min="0" autocomplete="off" />
              </mat-form-field>
              @if (!isRadioBroadcastPropertyType()) {
              <mat-checkbox formControlName="IsRateCpm" title="Select if rate is cost per mille (cost per 100 listeners)">Is Rate CPM</mat-checkbox>
              }
            </mat-card>
            }
            @if ('showMultimediaRecovery' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{multiMediaRecoveryLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{multiMediaRecoveryLabelText}}" formControlName="MultimediaRecovery" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showRevenue' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{revenueLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{revenueLabelText}}" formControlName="Revenue" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showProduction' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>{{productionLabelText}}</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="{{productionLabelText}}" formControlName="Production" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
          </mat-card>
          }
          @if (('showOnAirTime' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showActivityStartTime' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showActivityEndTime' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            <div style="margin-top: -10px;">
              @if ('showOnAirTime' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <kendo-floatinglabel text="On Air Time">
                <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="OnAirTime" [format]="'HH:mm'" [popupSettings]="popupSettings" placeholder="" (valueChange)="onAirTimeChange($event)"></kendo-timepicker>
              </kendo-floatinglabel>
              }
            </div>
            <div style="margin-top: 5px;">
              @if ('showActivityStartTime' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <kendo-floatinglabel text="Onsite Start Time">
                <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="StartTime" [format]="'HH:mm'" [popupSettings]="popupSettings" placeholder="" (valueChange)="onStartTimeChange($event)"></kendo-timepicker>
              </kendo-floatinglabel>
              }
            </div>
            <div style="margin-top: 5px;">
              @if ('showActivityEndTime' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
              <kendo-floatinglabel text="Onsite End Time">
                <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="EndTime" [format]="'HH:mm'" [popupSettings]="popupSettings" placeholder="" (valueChange)="onEndTimeChange($event)"></kendo-timepicker>
              </kendo-floatinglabel>
              }
            </div>
          </mat-card>
          }
          @if (('showWhereisUrl' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showTicketingOutlet' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showTicketingAllocation' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showWhereisUrl' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>Map Ref. Link</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="Map Ref. Link" title="{{formSSI.get('WhereisUrl').value}}" formControlName="WhereisUrl" maxlength="200" autocomplete="off" />
            </mat-form-field>
            }
            @if ('showTicketingOutlet' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div>
              <kendo-floatinglabel text="Third Party (eg Ticketing)">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{formSSI.get('TicketingOutlet').value}}" formControlName="TicketingOutlet" maxlength="200" autocomplete="off"></kendo-textbox>
              </kendo-floatinglabel>
            </div>
            }
            @if ('showTicketingAllocation' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <div>
              <kendo-floatinglabel text="Allocated Support (eg Tickets)">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{formSSI.get('TicketingAllocation').value}}" formControlName="TicketingAllocation" maxlength="300" autocomplete="off"></kendo-textbox>
              </kendo-floatinglabel>
            </div>
            }
          </mat-card>
          }
          @if (('showBroadcastingLive' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showHasOTP' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showHasPageBackground' | activityDisplayField: currentPropertyType?.PropertyTypeName) || ('showHasAdditionalInfoAttachment' | activityDisplayField: currentPropertyType?.PropertyTypeName)) {
          <mat-card appearance="outlined" class="control-mat-card-vertical">
            @if ('showBroadcastingLive' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="BroadcastingLive">{{broadcastingLiveLabelText}}</mat-checkbox>
            }
            @if ('showHasOTP' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="HasOTP">Has OTP</mat-checkbox>
            }
            @if ('showHasPageBackground' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-checkbox formControlName="HasPageBackground">Has Page Background</mat-checkbox>
            }
            @if ('showHasPageBackground' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
            <mat-form-field>
              <mat-label>No Of Page Backgrounds</mat-label>
              <input matInput (focus)="$event.target.select()" placeholder="No Of Page Backgrounds" formControlName="NoOfPageBackgrounds" min="0" type="number" autocomplete="off" />
            </mat-form-field>
            }
            @if (('showHasAdditionalInfoAttachment' | activityDisplayField: currentPropertyType?.PropertyTypeName) && !isNew() && formSSI.get('ActivityId').value > 0 && formSSI.get('StationId').value > 0) {
            <document-uploader formControlName="AdditionalInfoUrl"
                               emptyMsg="No Attachment"
                               subFolder="Attachments"
                               [uploadsFolder]="configurationService?.cbSettings?.uploadsDirectory"
                               [recordId]="formSSI.get('ActivityId')?.value"
                               [recordSubId]="formSSI.get('StationId')?.value"
                               [sizeLimit]="configurationService.cbSettings().additionalInfoSizeLimit"
                               showLabel="true"
                               labelText="Attachment"
                               style="margin-left:20px">
            </document-uploader>
            }
          </mat-card>
          }
          @if ('showOnsiteContact' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="contact-selector-wrapper">
            <fieldset>
              <legend>Onsite Contact Info</legend>
              <div class="contact-wrapper">
                <fa-icon [icon]="faUser" class="contact-icon"></fa-icon>
                <kendo-floatinglabel text="Onsite Contact">
                  <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{formSSI.get('OnsiteContactName').value}}" formControlName="OnsiteContactName" maxlength="200" autocomplete="off"></kendo-textbox>
                </kendo-floatinglabel>
              </div>
              <div class="contact-wrapper">
                <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
                <kendo-floatinglabel text="Onsite Contact Phone">
                  <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{formSSI.get('OnsiteContactTel').value}}" formControlName="OnsiteContactTel" maxlength="25" autocomplete="off"></kendo-textbox>
                </kendo-floatinglabel>
              </div>
            </fieldset>
          </div>
          }
          @if (('showAirtimeGrid' | activityDisplayField: currentPropertyType?.PropertyTypeName) || currentActivity?.PreRecorded) {
          <div class="span-all-grid-columns">
            <div>
              <kendo-label>{{airtimeCreditsText}}</kendo-label>
              <airtime-credits formControlName="ActivityCreditInfos" (valueChange)="airtimeCreditChange($event)" [isDisabled]="formSSIControlsDisabled"></airtime-credits>
            </div>
          </div>
          }
          @if ('showAirtimeBreakdown' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="span-all-grid-columns">
            <kendo-floatinglabel text="{{airtimeBreakdownLabelText}}">
              <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="3" selectOnFocus="true" title="{{formSSI.get('AirtimeBreakdown').value}}" formControlName="AirtimeBreakdown" maxlength="600" resizable="none"></kendo-textarea>
            </kendo-floatinglabel>
          </div>
          }
          @if ('showMechanic' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="span-all-grid-columns">
            <kendo-floatinglabel text="{{mechanicLabelText}}">
              <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="4" selectOnFocus="true" title="{{formSSI.get('Mechanic').value}}" formControlName="Mechanic" maxlength="3000" resizable="none"></kendo-textarea>
            </kendo-floatinglabel>
          </div>
          }
          @if ('showSignageRequired' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="span-all-grid-columns">
            <kendo-floatinglabel text="{{signageLabelText}}">
              <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="3" selectOnFocus="true" title="{{formSSI.get('SignageRequired').value}}" formControlName="SignageRequired" maxlength="1000" resizable="none"></kendo-textarea>
            </kendo-floatinglabel>
          </div>
          }
          @if ('showLocationAddress' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="span-all-grid-columns">
            <kendo-floatinglabel text="Location Address">
              <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="3" selectOnFocus="true" title="{{formSSI.get('LocationAddress').value}}" formControlName="LocationAddress" maxlength="500" resizable="none"></kendo-textarea>
            </kendo-floatinglabel>
          </div>
          }
          @if ('showParkingInstructions' | activityDisplayField: currentPropertyType?.PropertyTypeName) {
          <div class="span-all-grid-columns">
            <kendo-floatinglabel text="{{parkingInstructionsLabelText}}">
              <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="3" selectOnFocus="true" title="{{formSSI.get('ParkingInstructions').value}}" formControlName="ParkingInstructions" maxlength="500" resizable="none"></kendo-textarea>
            </kendo-floatinglabel>
          </div>
          }
          <div class="span-all-grid-columns">
            <kendo-floatinglabel text="{{additionInfoLabelText}}">
              <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="5" selectOnFocus="true" title="{{formSSI.get('AdditionalInfo').value}}" formControlName="AdditionalInfo" maxlength="4000" resizable="none"></kendo-textarea>
            </kendo-floatinglabel>
          </div>
        </div>
      </mat-tab>
      }
    </mat-tab-group>
    }
    @if (!isAuthorised()) {
    <div class="not-authorised">
      <div class="not-authorised-msg">
        <table style="padding: 10px;">
          <tr>
            <td rowspan="2" style="padding:5px"><img src="assets/images/attention.png" style="height:50px; min-width: 50px;" /></td>
            <td style="padding:10px 10px 0 10px"><label style="font-weight:bold; color:red">{{notAuthorisedMsg}}</label></td>
          </tr>
          <tr>
            <td style="padding:0 10px 10px 10px"><label style="font-weight:bold; color:red">This activity is confidential and you are not authorised to view the contents.</label></td>
          </tr>
        </table>
      </div>
    </div>
    }
  </div>
  }
  @if (saveInProgress) {
  <div></div>
  }
  @if (saveInProgress) {
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
    @if (saveInProgress) {
    <label class="spinner-loading-label">Saving Activity, please wait...</label>
    }
  </div>
  }
  @if (saveInProgress) {
  <div></div>
  }
  @if (showFooter) {
  <div class="activity-footer">
    <hr style="margin-top: 0.2rem; margin-bottom: 0.75rem;" />
    <mat-dialog-actions>
      <div class="button-row">
        <div>
          @if (showCancelButton) {
          <button mat-button [disabled]="saveInProgress" (click)="onCancelClick()">Close</button>
          }
          <button mat-button color="primary" [disabled]="!isAuthorised() || !form.valid || !formSSI.valid || !form.dirty || !stationSpecificInfoTabValid || (activityService.stationIdsWithInvalidValues && activityService.stationIdsWithInvalidValues.length > 0) || saveInProgress" (click)="onSave()">Save</button>
        </div>
        @if (canDeleteActivity && form.get('Id').value > 0) {
        <button mat-button class="delete-button" [disabled]="saveInProgress" (click)="delete($event)">Delete</button>
        }
      </div>
    </mat-dialog-actions>
  </div>
  }
</div>
